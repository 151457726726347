
































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/main/types';
import { stampToReadable } from '@/utilities/filters';
import { NewsItem } from '@/interfaces/NewsItem';

@Component({
  filters: { stampToReadable },
})
export default class News extends Vue {

  @Getter('news', { namespace: 'main' }) private news!: NewsItem[];
  @Action('loadNews', { namespace: 'main' }) private loadNews!: types.LoadNewsAction;

  private searchText = '';
  private pageLength = 20;
  private currentPage = 1;
  private loadingNews = false;

  private created() {
    this.loadingNews = true;
    this.loadNews().finally(() => {
      this.loadingNews = false;
    });
  }

  private get trimmedSearch() {
    return this.searchText.trim().toLowerCase();
  }

  private get searchedNews() {
    return this.news.filter((item) => {
      return (item.title || '').toLowerCase().includes(this.trimmedSearch) ||
        (item.text || '').toLowerCase().includes(this.trimmedSearch);
    });
  }

  /**
   * Paginate the filtered entries to get a single page's length
   */
  get paginatedNews() {
    return this.searchedNews.slice((this.currentPage - 1) * this.pageLength, this.currentPage * this.pageLength);
  }
}
